<template>
        <base-page>
        <titlu-pagina Titlu="Fisa intrare" @on_add_clicked="show_dialog()" :AdaugaVisible="$has_right('adaugare-fisa-intrare')" />
        <el-card style='margin:5px 0px 5px 0px'>
            <div slot="header" class="clearfix">
                <strong> Filtre </strong>
            </div>
            <div class="filtre">
                <el-form @submit.prevent.native='refresh_info()'>
                    <el-row :gutter="20">
                        
                        <el-col :md='4'>
                            <el-form-item label='Masina' >
                                <el-select class='full-width' v-model='Filters.IdMasina' >
                                    <el-option label='Toate' value='-1'></el-option>
                                    <el-option v-for='item in Info.masini' :key="'masini' + item.Id" :label='item.Numar' :value='item.Id'></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :md='4'>
                            <el-form-item label='Client' >
                                <el-select class='full-width' v-model='Filters.IdClient' >
                                    <el-option label='Toate' value='-1'></el-option>
                                    <el-option v-for='item in Info.clienti' :key="'clienti' + item.Id" :label='item.Nume' :value='item.Id'></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :md='4'>
                            <el-form-item label='Mecanic 1' >
                                <el-select class='full-width' v-model='Filters.IdMecanic1' >
                                    <el-option label='Toate' value='-1'></el-option>
                                    <el-option v-for='item in Info.utilizatori' :key="'utilizatori' + item.Id" :label='item.Nume' :value='item.Id'></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :md='4'>
                            <el-form-item label='Mecanic 2' >
                                <el-select class='full-width' v-model='Filters.IdMecanic2' >
                                    <el-option label='Toate' value='-1'></el-option>
                                    <el-option v-for='item in Info.utilizatori' :key="'utilizatori' + item.Id" :label='item.Nume' :value='item.Id'></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :md='4'>
                            <el-form-item label='Data adaugare' >
                                <el-date-picker v-model='Filters.DataAdaugare' type='date' value-format='yyyy-MM-dd' format='dd.MM.yyyy' />
                            </el-form-item>
                        </el-col>
                        <el-col :md='4'>
                            <el-form-item label='Nr. km' >
                                <el-input-number class='full-width' v-model='Filters.NrKm' :precision='0' :max='999999'></el-input-number>
                            </el-form-item>
                        </el-col>
                        <el-col :md='4'>
                            <el-form-item label='Status' >
                                <el-select class='full-width' v-model='Filters.Status' >
                                    <el-option label='Toate' value='-1'></el-option>
                                    <el-option label='inchis' value='inchis'></el-option>
                                    <el-option label='deschis' value='deschis'></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :md='4'>
                            <el-form-item label='Programare' >
                                <el-select class='full-width' v-model='Filters.IdProgramare' >
                                    <el-option label='Toate' value='-1'></el-option>
                                    <el-option v-for='item in Info.programari' :key="'programari' + item.Id" :label='item.Status' :value='item.Id'></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>                        
                        <el-col :md='24' >
                            <el-button type='primary' native-type='submit' @click='refresh_info()'> Aplica </el-button>
                            <el-button @click='reset'> Reset </el-button>
                        </el-col>

                    </el-row>
                </el-form>
                </div>
        </el-card>

        <el-table :data="Results" >
            <el-table-column prop='NumarMasina' label='Masina'></el-table-column>
            <el-table-column prop='NumeClient' label='Client'></el-table-column>
            <el-table-column prop='NumeMecanic1' label='Mecanic 1'></el-table-column>
            <el-table-column prop='NumeMecanic2' label='Mecanic 2'></el-table-column>
            <el-table-column label='Data adaugare'>
                <template slot-scope='scope'>
                    {{ scope.row.DataAdaugare | momentformat('dd.MM.YYYY HH:mm') }} 
                </template>
            </el-table-column>
            <el-table-column prop='NrKm' label='Nr. km'></el-table-column>
            <el-table-column prop='Status' label='Status'></el-table-column>
            <el-table-column prop='IdProgramare' label='Programare'></el-table-column>
            <el-table-column fixed="right" label="Actiuni" width="200px" >
                <template slot-scope="scope" >

                    <el-tooltip v-if="$has_right('modificare-fisa-intrare')" content="Modificare">
                        <el-button type="primary" icon="el-icon-edit"  circle @click="show_dialog(scope.row.Id)" />
                    </el-tooltip>

                    <el-tooltip v-if="$has_right('stergere-fisa-intrare')" content="Sterge" >
                        <el-button type="danger" icon="el-icon-delete" circle @click="delete_item(scope.row)" />
                    </el-tooltip>

                </template>
            </el-table-column>
        </el-table>
    <el-pagination @size-change="refresh_info" @current-change= "refresh_info" :page-size.sync="PaginationInfo.PerPage" :current-page.sync="PaginationInfo.Page" :total="PaginationInfo.RowCount" layout="pager" />
    <Fisa-intrare-dialog ref='dlg' @save="refresh_info()" />
</base-page>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Fisa_intrare_dialog from '@/pages/fisa_intrare/Fisa_intrare_dialog.vue';
import TitluPagina from '@/widgets/TitluPagina';

export default {
    name: "fisa_intrare",
    extends: BasePage,
    components: {
        'base-page': BasePage,
        'Fisa-intrare-dialog': Fisa_intrare_dialog,
        'titlu-pagina': TitluPagina
    },
    data () {
        return {
            Results: [],
            base_url: '',
            Info: {
                masini: [], clienti: [], utilizatori: [], utilizatori: [], programari: [],             },
            Filters: {
                IdMasina: '-1' , IdClient: '-1' , IdMecanic1: '-1' , IdMecanic2: '-1' , DataAdaugare: '' , NrKm: '' , Status: '-1' , IdProgramare: '-1' ,             },
            OrderBy: { },
            PaginationInfo: { Page: 1, PerPage: 50, RowCount: 0, PageSizes: [10, 25, 50, 100, 200] },
        }
    },
    methods: {

        async get_info(){
            if (!this.$has_right('vizualizare-fise-intrare')){
                this.$router.push('/no-rights')
                return
            }
            var response        = await this.post("fisa_intrare/get_info" );
            this.Info.masini = response.masini;
            //if( this.Filters.IdMasina.length > 0 ) this.Filters.IdMasina = this.Info.masini[0].Id;
            this.Info.clienti = response.clienti;
            //if( this.Filters.IdClient.length > 0 ) this.Filters.IdClient = this.Info.clienti[0].Id;
            this.Info.utilizatori = response.utilizatori;
            //if( this.Filters.IdMecanic1.length > 0 ) this.Filters.IdMecanic1 = this.Info.utilizatori[0].Id;
            this.Info.utilizatori = response.utilizatori;
            //if( this.Filters.IdMecanic2.length > 0 ) this.Filters.IdMecanic2 = this.Info.utilizatori[0].Id;
            this.Info.programari = response.programari;
            //if( this.Filters.IdProgramare.length > 0 ) this.Filters.IdProgramare = this.Info.programari[0].Id;
            this.refresh_info();
        },

        async refresh_info(){
            var response        = await this.post("fisa_intrare/index", { Filters: this.Filters, OrderBy: this.OrderBy, PaginationInfo: this.PaginationInfo } );
            this.Results        = response.Results;
            this.PaginationInfo = response.PaginationInfo;
            //
            this.select_menu_item('fisa_intrare');
        },
        reset(){
            this.Filters = {
                IdMasina: '-1' , IdClient: '-1' , IdMecanic1: '-1' , IdMecanic2: '-1' , DataAdaugare: '' , NrKm: '' , Status: '-1' , IdProgramare: '-1' ,             };
            this.refresh_info();
        },
    
        async delete_item( item ){            
            this.$confirm(`Sunteti sigur ?`, 'Warning', {               
                type: 'warning'
                }).then(async() => {
                    await this.post("fisa_intrare/delete_item", { id: item.Id } );
                    this.refresh_info(); 
                    this.$message({type: 'info', message: 'Stergere efectuata cu succes'});                                                                       
                    }).catch(() => {
                        this.$message({type: 'info', message: 'Stergere anulata'});                                                                 
                        });
        },

        show_dialog(id){
            
            this.$refs.dlg.show_me(id);
        }
    },
    mounted(){
        this.base_url = settings.BASE_URL;
        this.get_info();
    }
};
</script>

<style lang="less" scoped>

    .top50{
        margin-top: 20px;
    }

    .filtre{
        .el-input-number
        {
            width: 100% !important;
        }
    }

</style>
